import Api from './Api';
import { setCookie } from '../cookies';

const api = new Api();
const SECONDS_IN_DAY = 60 * 60 * 24;
const NAME_APP = 'WEB_managers';

export const auth = {
  namespaced: true,
  state: {
    user: {},
    IMEI: '',
    hash: '',
    passwordLength: 0,
  },
  actions: {
    async getImei({ commit }) {
      try {
        const imei = await api.IMEI;
        setCookie('IMEI', imei, { secure: true, 'max-age': SECONDS_IN_DAY });
        return commit('SET_IMEI', imei);
      } catch (error) {
        throw new Error(error);
      }
    },
    async setPassword({ state }, pswdParams) {
      const params = JSON.stringify({
        Name_event: 'POST:passworrd_change',
        password: pswdParams.password,
        hash: state.hash,
      });
      try {
        const result = await api.request('changePassword', 'POST', `json=${params}`);
        if (result) {
          return result[0];
        }
      } catch (error) {
        console.log(error);
      }
    },
    async checkHash({ commit }, hash) {
      const params = JSON.stringify({
        hash,
      });
      try {
        const loginByHash = await api.request('verifyHash', 'POST', `json=${params}`);
        commit('SET_HASH', hash);
        return loginByHash[0].link_status;
      } catch (error) {
        console.log(error);
      }
    },
    async signIn({ state, commit }, loginData) {
      if (!state.IMEI) {
        return;
      }
      const params = JSON.stringify({
        ...loginData,
        Name_app: NAME_APP,
        IMEI: state.IMEI,
      });
      try {
        const userData = await api.request('login', 'POST', `json=${params}`);
        if (userData) {
          setCookie('id_login', userData[0].id_login, { secure: true, 'max-age': SECONDS_IN_DAY });
          setCookie('TK', userData[0].TK, { secure: true, 'max-age': SECONDS_IN_DAY });
          commit('SET_USER', userData[0]);
        }
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async newLinkRequest({ state }, email) {
      const params = JSON.stringify({
        login: state.user.id_login,
        mail: email,
      });
      try {
        const result = await api.request('loginLinkSend', 'POST', `json=${params}`);
        return result;
      } catch (error) {
        console.log(error);
      }
    },
    async sendResetRequest({ commit }, resetData) {
      const params = JSON.stringify({
        contact: resetData.email,
        contactType: resetData.contactType,
      });
      try {
        const result = await api.request('sendResetLink', 'POST', `json=${params}`);
        if (result) {
          return result[0];
        }
      } catch (error) {
        console.log(error);
      }
    },
    async unAuthorized() {
      console.log('unauth');
    },
  },
  getters: {

  },
  mutations: {
    SET_IMEI(state, imei) {
      state.IMEI = imei;
    },
    SET_USER(state, userData) {
      state.user = userData;
    },
    SET_HASH(state, hash) {
      state.hash = hash;
    },
    SET_PASSWORD_LENGTH(state, length) {
      state.passwordLength = length;
    },
  },
};
