<template>
  <router-link to="/login">
    <div class="logo-container">
      <img class="img-logo"
        src="./assets/ecosagety_logo_group_2018.png" alt="">
      <img class="img-logo"
        src="./assets/medsafe-logo.png" alt="">
    </div>
  </router-link>
  <div class="browser-tooltip" v-if="passwordLength && isChrome">
    <span>Если это ваше постоянное устройство,<br/>
      можно сохранить логин и пароль,<br/>
      нажав на ключик вот здесь</span>
      <mcw-material-icon icon="north_east"></mcw-material-icon>
  </div>
  <router-view/>
</template>

<script>
import { detect } from 'detect-browser';

export default {
  name: 'App',
  data() {
    return {
      showBrowserTooltip: false,
    };
  },
  computed: {
    passwordLength() {
      return this.$store.state.auth.passwordLength;
    },
    isChrome() {
      return detect().name === 'chrome';
    },
  },
  created() {
    const { opener, location } = window;
    if (!opener && location.hostname !== 'localhost') {
      location.replace(location.origin);
    }
  },
  async mounted() {
    console.log('ver: 08.02.23_0');
    await this.$store.dispatch('auth/getImei');
  },
};
</script>

<style lang="scss">
@use "styles/theme";
@use "@material/button/mdc-button";
@use "@material/floating-label/mdc-floating-label";
@use "@material/line-ripple/mdc-line-ripple";
@use "@material/notched-outline/mdc-notched-outline";
@use "@material/textfield/mdc-text-field";
@use "@material/snackbar/mdc-snackbar";
input:focus{
  background-color: #fff;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
  .login-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 400px;
    margin: 0 auto;
  }
  .field-group{
    position: relative;
    margin-bottom: 30px;
  }
  .img-logo{
    max-width: 353px;
  }
  .logo-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .browser-tooltip{
    position: absolute;
    top: 10px;
    right: 28px;
    display: flex;
    & span {
      text-align: left;
    }
    & .material-icons {
      font-size: 48px;
    }
  }
</style>
