<template>
  <h1>{{labels.pageHeading}}</h1>
  <h4
    v-if="showExpiredMessage">
    {{labels.expiredAndUsed}}
  </h4>
  <form class="login-form"
    @submit.prevent="login">
    <div class="field-group">
      <mcw-textfield
        v-model.trim="username"
        :label="labels.loginInput"
        :helptext="labels.noticeValidation"
        type="text"
        name="login"
        id="login"
        outline
        secondary
        autocomplete="login"
        ref="login"
      ></mcw-textfield>
    </div>
    <div class="field-group">
      <mcw-textfield
        v-model.trim="password"
        :label="labels.passwordInput"
        :helptext="labels.noticeValidation"
        :type="passwordVisible ? 'text' : 'password'"
        name="password"
        outline
        id="password"
        secondary
        autocomplete="password"
        ref="password"
        trailing-icon="edit"
        @keypress.enter="test($event)"
      />
      <mcw-button
        class="password-toggle-btn"
        :icon="!passwordVisible ? 'visibility' : 'visibility_off'"
        @click.prevent="passwordVisible = !passwordVisible"
      ></mcw-button>
      </div>
      <template v-if="twoFactorOn">
      <div class="field-group">
        {{ labels.codeMessage }}
      </div>
      <div class="field-group">
        <mcw-textfield
          v-model.trim="code"
          :label="labels.codeInput"
          outline
          secondary
        />
      </div>
    </template>
    <div class="error-message" v-if="authError">
      {{ authErrorText }}
    </div>
    <mcw-button
      type="submit"
      raised>
      {{labels.enterButton}}
    </mcw-button>
    <router-link
      to="reset-password"
      class="login-link">
      {{labels.forgetPassword}}
    </router-link>
  </form>
  <mcw-snackbar
    v-model="noticeVisible"
    :message="notice"
    :dismissAction="true"
  ></mcw-snackbar>
  <mcw-snackbar
    v-model="isEmpty"
    :message="labels.emptyField"
    :dismissAction="false"
  ></mcw-snackbar>
  <mcw-snackbar
    v-model="showUncorrectHashMessage"
    :message="labels.uncorrectHashMessage"
    :dismissAction="false"
  ></mcw-snackbar>
</template>

<script>
import { twoFactorAuthError, incorrectCodeError, CODE_LENGTH } from '../constants';

let PARENT_HOST = window.location.origin;
if (PARENT_HOST.indexOf('localhost') !== -1) {
  PARENT_HOST = 'http://localhost:8080';
}

export default {
  name: 'NewPassword',
  data() {
    return {
      labels: {
        pageHeading: 'Авторизация',
        loginInput: 'Ваш логин',
        passwordInput: 'Ваш пароль',
        codeInput: 'Проверочный код',
        codeMessage: 'Код отправлен, проверьте ваши сообщения',
        enterButton: 'Вход',
        noticeWrongPassword: 'Неверные данные для входа',
        forgetPassword: 'Сменить пароль',
        emptyField: 'Введите имя пользователя и пароль',
        expiredAndUsed: 'Срок действия персональной ссылки истек. Возможен вход по логину и паролю.',
        uncorrectHashMessage: 'Неверная ссылка',
        uncorrectCodeMessage: 'Неверный код',
      },
      username: '',
      password: '',
      code: '',
      noticeVisible: false,
      notice: '',
      isEmpty: false,
      showExpiredMessage: false,
      showUncorrectHashMessage: false,
      passwordVisible: false,
      twoFactorOn: false,
      authError: false,
      authErrorText: '',
    };
  },
  watch: {
    password(val) {
      this.$store.commit('auth/SET_PASSWORD_LENGTH', val.length);
    },
    code() {
      this.checkCodeLength();
    },
  },
  mounted() {
    if (this.$route.hash) {
      this.signInByHash(this.$route.hash.substr(1));
    }
    const { login, password } = this.$refs;
    if (login) {
      const wrapper = login.root;
      const label = wrapper.querySelector('.mdc-floating-label');
      const border = wrapper.querySelector('.mdc-notched-outline');
      wrapper.classList.add('mdc-text-field--label-floating');
      label.classList.add('mdc-floating-label--float-above');
      border.classList.add('mdc-notched-outline--notched');
    }
    if (password) {
      const wrapper = password.root;
      const label = wrapper.querySelector('.mdc-floating-label');
      const border = wrapper.querySelector('.mdc-notched-outline');
      wrapper.classList.add('mdc-text-field--label-floating');
      label.classList.add('mdc-floating-label--float-above');
      border.classList.add('mdc-notched-outline--notched');
    }
  },
  methods: {
    showAuthError(text = '') {
      this.authErrorText = text;
      this.authError = true;
    },
    checkCodeLength() {
      if (this.code.length === CODE_LENGTH) {
        this.login();
      }
    },
    test(ev) {
      ev.preventDefault();
      this.login();
    },
    async signInByHash(hash) {
      const hashStatus = await this.$store.dispatch('auth/checkHash', hash);
      switch (hashStatus) {
        case 1:
          this.$router.push('/new-password');
          break;
        case 2:
          this.$router.push('/unauthorized');
          break;
        case 3:
          this.isExpired = true;
          this.showExpiredMessage = true;
          break;
        case 4:
          this.isExpired = true;
          this.$router.push('/new-link');
          break;
        default:
          this.showUncorrectHashMessage = true;
          return false;
      }
    },
    checkFields() {
      return this.username && this.password;
    },
    async login() {
      this.authError = false;
      if (!this.checkFields) {
        this.isEmpty = true;
        return;
      }
      const userData = {
        login: this.username,
        password: this.password,
      };
      if (this.code.length === CODE_LENGTH) {
        userData.code = this.code;
      }
      await this.$store.dispatch('auth/signIn', userData);

      const { user } = this.$store.state.auth;
      if (user.err && user.err === twoFactorAuthError) {
        this.twoFactorOn = true;
        return;
      }
      if (user.err && user.err === incorrectCodeError) {
        this.showAuthError(this.labels.uncorrectCodeMessage);
        return;
      }
      if (user.id_login) {
        window.opener.postMessage('login', PARENT_HOST);
        window.close();
        return;
      }
      this.showAuthError(this.labels.noticeWrongPassword);
    },
  },
};
</script>

<style>
  .login-link{
    margin-top: 30px;
    cursor: pointer;
  }
  .password-toggle-btn {
    position: absolute;
    top: 10px;
    right: 0;
  }
  .error-message {
    color: red;
    margin: 10px auto;
  }
</style>
