import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import store from '../store/index';
import LoginForm from '../components/LoginForm.vue';

const routes = [
  // {
  //   path: '/auth-success', // этот роут надо удалить перед деплоем
  //   name: 'Placeholder',
  //   component: () => import('../components/Placeholder.vue'),
  // },
  {
    path: '/login',
    name: 'LoginForm',
    component: LoginForm,
  },
  {
    path: '/new-password',
    name: 'NewPassword',
    component: () => import('../components/NewPassword.vue'),
  },
  {
    path: '/new-link',
    name: 'NewLink',
    component: () => import('../components/NewLink.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../components/ResetPassword.vue'),
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import('../components/Unauthorized.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('../components/404.vue'),
  },
];

const router = createRouter({
  // history: createWebHashHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  const currentUser = store.state.auth.user.id_login;
  if (!currentUser || currentUser === 0) {
    const allowedRoute = routes.find((route) => to.path.toLowerCase() === route.path);
    if (!allowedRoute) {
      router.push('/login');
    }
  }
});

export default router;
