// import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import md5 from 'blueimp-md5';
import { BASE_URL } from '../constants';

const getImei = async function getImei() {
  const fpPromise = FingerprintJS.load({ token: process.env.VUE_APP_FP });
  const fp = await fpPromise;
  const result = await fp.get();
  return md5(result.visitorId);
};

export default class Api {
  constructor() {
    this.IMEI = getImei();
  }

  async request(url, method, payload) {
    try {
      const response = await fetch(`${BASE_URL}${url}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method,
        body: payload,
      });
      const result = await response.json();
      console.log(payload, result);
      return result;
    } catch (error) {
      return error;
    }
  }
}
