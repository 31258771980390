// import Api from './Api';

// const api = new Api();

export const user = {
  namespaced: true,
  state: {
    id_login: null,
  },
};
